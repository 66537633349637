<template>
  <div>
    <div :class="isHome ? 'order-helper' : 'update-vech'" @click="showForm">
      <i class="iconfont its_ba_faqirenwu1" v-if="isHome"></i>
      {{ isHome ? '工单助手' : '' }}
    </div>
    <el-dialog
      title="工单申请"
      width="42%"
      :class="
        isHome ? 'order-dialog home-order-dialog' : 'order-dialog other-header'
      "
      :modal-append-to-body="true"
      :visible.sync="visible"
      :modal="false"
      @closed="closeDia"
    >
      <el-form ref="form" label-width="90px" :model="ruleForm" :rules="rules">
        <div class="scoll-container">
          <el-form-item label="工单类型：" prop="applicationTypeId">
            <el-select
              v-model="ruleForm.applicationTypeId"
              placeholder="请选择工单类型"
              size="small"
              @change="
                (val) => {
                  orderTypeList.forEach((type) => {
                    if (type.dictCode === val)
                      ruleForm.applicationType = type.dictValue
                  })
                  if (val == 1) delete ruleForm.file
                  this.$refs.form.clearValidate()
                }
              "
            >
              <el-option
                v-for="item in orderTypeList"
                :key="item.dictCode"
                :value="item.dictCode"
                :label="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="车牌号："
                prop="licensePlate"
                :rules="
                  ruleForm.applicationTypeId == '4' ||
                  ruleForm.applicationTypeId == '5'
                    ? rules.licensePlate
                    : [{ required: false }]
                "
              >
                <el-input
                  v-model.trim="ruleForm.licensePlate"
                  size="small"
                  :disabled="vehDisabled"
                  placeholder="请填写车牌号"
                  @input="licensePlateINPUT"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车牌颜色：" prop="licensePlateColorId">
                <el-select
                  v-model.trim="ruleForm.licensePlateColorId"
                  :disabled="vehDisabled"
                  placeholder="车牌颜色"
                  @change="changeColor"
                >
                  <el-option
                    v-for="item in plateColorList"
                    :key="item.dictCode"
                    :value="item.dictCode"
                    :label="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="终端类型："
                prop="vehicleTerminalTypeId"
                :rules="
                  ruleForm.applicationTypeId == '5'
                    ? rules.vehicleTerminalTypeId
                    : [{ required: false }]
                "
              >
                <el-select
                  size="small"
                  :disabled="vehDisabled"
                  v-model="ruleForm.vehicleTerminalTypeId"
                  @change="
                    (val) => {
                      deviceTypes.forEach((type) => {
                        if (type.dictCode === val)
                          ruleForm.vehicleTerminalType = type.dictValue
                      })
                    }
                  "
                >
                  <el-option
                    v-for="item in deviceTypes"
                    :key="item.dictCode"
                    :value="item.dictCode"
                    :label="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
            v-if="
              ruleForm.applicationTypeId &&
              (ruleForm.applicationTypeId == 2 ||
                ruleForm.applicationTypeId == 3)
            "
            label="文件上传："
            prop="files"
          >
            <el-upload
              ref="uploader"
              :action="uploadUrl"
              :auto-upload="false"
              :multiple="true"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.doc,.docx,.xls,.xlsx,.pdf"
              :on-change="getFile"
              :on-remove="removeFile"
            >
              <el-button type="text">上传运政证明文件</el-button>
            </el-upload>
            <span v-if="!ruleForm.files"
              >支持文件类型:<br />
              图片 .jpg/jpeg .png .gif .bmp, <br />
              文档 .doc/docx .xls/.xlsx/.pdf
            </span>
          </el-form-item>

          <el-row>
            <el-col :span="12">
              <el-form-item
                label="联系人："
                prop="contacts"
                :rules="
                  ruleForm.applicationTypeId == '4'
                    ? rules.contacts
                    : [{ required: false }]
                "
              >
                <el-input
                  v-model="ruleForm.contacts"
                  size="small"
                  placeholder="联系人姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="电话："
                prop="contactsPhone"
                :rules="
                  ruleForm.applicationTypeId == '4'
                    ? rules.contactsPhone
                    : [{ required: false }]
                "
              >
                <el-input
                  v-model="ruleForm.contactsPhone"
                  placeholder="联系人电话号码"
                  size="small"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
            label="车辆位置："
            prop="vehicleLocation"
            :rules="
              ruleForm.applicationTypeId == '4'
                ? rules.vehicleLocation
                : [{ required: false }]
            "
          >
            <el-input
              v-model="ruleForm.vehicleLocation"
              placeholder="车辆位置"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="问题描述："
            prop="problemDescription"
            v-if="isHome"
          >
            <el-input
              v-model="ruleForm.problemDescription"
              placeholder="请输入"
              autosize
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="时间：" prop="time">
            {{ time }}
          </el-form-item>
        </div>
        <el-form-item>
          <el-button @click="submit" type="primary" :disabled="submiting"
            >提交</el-button
          >
          <el-button @click="closeDia">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/api/static/config";
import {
  queryDictsByCodes,
  submitWorkOrder,
  getPlateColorByLiscense,
} from "@/api/lib/api.js";
import { YHTools } from "@/assets/js/Tools.js";
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      visible: false,
      ruleForm: {},
      time: "",
      date: new Date(),
      rules: {
        applicationTypeId: [
          { required: true, message: "工单类型不能为空", trigger: "change" },
        ],
        licensePlate: [
          { required: true, message: "车牌号不能为空", trigger: "blur" },
        ],
        vehicleTerminalTypeId: [
          { required: true, message: "终端类型不能为空", trigger: "blur" },
        ],
        files: [
          // { required: true, message: "证明文件不能为空", trigger: "change" },
          {validator: (rule, value, callback) =>{
          if(value){
            const allowedFileNameRegex = /^[a-zA-Z0-9_-]+\.(jpg|jpeg|png|gif)$/;
            value.forEach(file=>{
              if(!allowedFileNameRegex.test(file.name)){
                callback(new Error("文件名不合法，请使用英文字母、数字、下划线和连字符"));
              }else{
                return callback();
              }
            })
          }else{
            callback(new Error("证明文件不能为空"));
          }
          },trigger: "change"
          }
        ],
        problemDescription: [
          { required: true, message: "问题描述不能为空", trigger: "blur" },
        ],
        licensePlateColorId: [
          // { required: true, message: "车牌颜色不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (this.ruleForm.applicationTypeId == '4' || this.ruleForm.applicationTypeId == '5') {
                if (this.ruleForm.licensePlateColorId) {
                  return callback();
                } else {
                  callback(new Error("车牌颜色不能为空"));
                }

              } else {
                return callback();
              }
            },
            trigger: "change"
          }
        ],
        contacts: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        contactsPhone: [
          { required: true, message: "电话不能为空", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!value) return callback();
              if (
                /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
                  value
                )
              ) {
                return callback();
              } else {
                callback(new Error("手机号码格式不正确"));
              }
            },
            trigger: "blur",
          },
        ],
        vehicleLocation: [
          { required: true, message: "车辆位置不能为空", trigger: "change" },
        ]
      },
      uploadUrl: config.apiBaseUrl + "",
      orderTypeList: [
        { dictCode: "4", dictValue: "新装" },
        { dictCode: "5", dictValue: "报修" },
        // { dictCode: "6", dictValue: "下线" },
      ],
      orderTypeListVeh: [
        { dictCode: "1", dictValue: "正常" },
        { dictCode: "2", dictValue: "停运" },
        { dictCode: "3", dictValue: "报废" },
      ],
      colorList: [{ dictCode: "123", dictValue: "1232" }],
      plateColorList: [],
      deviceTypes: [{ dictCode: "123", dictValue: "1232" }],
      lineTypes: [{ dictCode: "123", dictValue: "1232" }],
      file: "",
      user: this.$store.state.user,
      submiting: false,
      vehDisabled: false,
    };
  },
  created () {
    if (!this.isHome) {
      this.orderTypeList = [...this.orderTypeListVeh];//车辆管理的状态数据
      //回显
      this.ruleForm.licensePlate = this.rowData.cph;
      this.plateColorList = [{ dictCode: this.rowData.licensePlateColor, dictValue: this.rowData.licensePlateColorStr }]
      this.ruleForm.licensePlateColorId = this.rowData.licensePlateColor;
      this.ruleForm.vehicleTerminalTypeId = this.rowData.deviceType;
      //工单类型-正常状态修改为正常状态不能提交
      this.ruleForm.applicationTypeId = this.rowData.vehicleStatus;
      this.vehDisabled = true;
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.time = YHTools.DateFormat(new Date(), "yyyy年MM月dd日 HH:mm:ss");
      this.date = new Date();
    }, 1000);

  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    changeColor (val) {
        this.plateColorList.forEach((type) => {
          if (type.dictCode === val)
            this.ruleForm.licensePlateColor = type.dictValue;
            this.ruleForm.licensePlateColorId = val;
        });
    },
    closeDia () {
      this.visible = false;
      this.ruleForm = {};
      this.$refs.form.clearValidate();
      this.$emit('closeDia', false)
    },
    showForm () {
      this.visible = true;
      this.getDictionaryData();
      this.$nextTick(() => {
        if (this.isHome) {
          this.ruleForm = {};
        }
        this.$refs.form.clearValidate();
      });
    },
    getDictionaryData () {
      queryDictsByCodes({ parentCodes: "CPYS,ZDLX,XLLX" }).then((res) => {
        if (res.data) {
          this.colorList = res.data.CPYS ?? [];
          this.deviceTypes = res.data.ZDLX ?? [];
          this.lineTypes = res.data.XLLX ?? [];
        }
      });
    },
    // 获取文件
    getFile (file, fileList) {
      this.$set(this.ruleForm, "files", fileList); 
      // const fileReader = new FileReader();
      // fileReader.readAsArrayBuffer(file.raw);
      // fileReader.onload = function (res) {
      //   const buffer = fileReader.result;
      //   const buffer_16 = new Uint8Array(buffer.slice(0, 20));
      //   const buf = [];
      //   buffer_16.forEach((item) => {
      //     buf.push(item.toString(16));
      //   });
      //   console.log(buf.join(""));
      //   console.log(/^FFD8FF/.test(buf.join("").toUpperCase()));
      // };
    },
    // 移除文件
    removeFile (file) {
      this.$refs['form'].clearValidate(['files']);
      let list = this.ruleForm.files;
      for (var a = 0; a < list.length; a++) {
        if (list[a] === file) {
          list.splice(a, 1);
          return;
        }
      }
    },
    // 查询车牌颜色
    licensePlateINPUT (license) {
      this.ruleForm.licensePlateColorId = "";
      this.$refs.form.clearValidate()
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        getPlateColorByLiscense({
          cph: license,
          companyId: localStorage.getItem("companyId"),
          system: sessionStorage.getItem("system"),
        }).then((res) => {
          let arr = [];
          res.data?.forEach((item) => {
            let result = this.colorList.find((color) => {
              return color.dictCode == item;
            });
            if (result) arr.push(result);
          });
          this.plateColorList = arr;
        });
      }, 1000);
    },
    // 提交工单
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          /**
           * 更改车辆状态：正常--> 正常  不能提交
           */
          if (!this.isHome) {
            if (this.rowData.vehicleStatus == this.ruleForm.applicationTypeId) {
              this.$message.warning("不能提交为原工单状态！")
              return
            }
          }
          this.submiting = true;
          this.ruleForm.companyId = localStorage.getItem("companyId");
          this.ruleForm.company = localStorage.getItem("companyName");
          this.ruleForm.time = YHTools.DateFormat(
            this.date,
            "yyyy-MM-dd HH:mm:ss"
          );
          this.ruleForm.problemDescription = this.ruleForm.applicationTypeId == 2 ? '停运' : this.ruleForm.applicationTypeId == 3 ? '报废' : this.ruleForm.problemDescription;
          const formData = new FormData();
          Object.keys(this.ruleForm).forEach((key) => {
            if (key != "files") formData.append(key, this.ruleForm[key]);
            else
              this.ruleForm[key].forEach((file) => {
                formData.append("file", file.raw);
              });
          });
          submitWorkOrder(formData)
            .then((res) => {
              if (res.code === 1000) {
                this.$message.success("工单已提交");
                this.visible = false;
              } else {
                this.$message.error(res.msg ? res.msg : "工单提交失败");
              }
              this.submiting = false;
            })
            .catch((err) => {
              this.$message.error("工单提交失败 " + err?.msg ? err.msg : "");
              this.submiting = false;
            });
        } else {
          this.submiting = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-helper {
  background: #fff;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #176fd422;
    color: #007aff;
    // border:1px solid #007aff;
  }
}
.update-vech {
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  color: #007aff;
  margin-right: 8px;
}
.order-dialog {
  /deep/.el-dialog {
    border-radius: 15px;
    box-shadow: 0 5px 5px 4px #aaa8;
    .el-dialog__header {
      border: none !important;
    }
    .scoll-container {
      max-height: calc(100vh - 400px);
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
    }
  }
}
// .other-header{
//   /deep/.el-dialog__header{
//     height: 58px !important;
//   }
// }
.home-order-dialog {
  /deep/.el-dialog {
    margin: 0 !important;
    position: absolute;
    right: 20px;
    top: 70px;
  }
}
</style>